import  { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function CreditMonitoringReport() {
    const [searchParams] = useSearchParams()

    useEffect(() => {
        window.addEventListener('array-event', arrayEventListener)

        return () => {
            window.removeEventListener('array-event')
        }
    }, [])

    const arrayEventListener = (arrayEvent) => {
        const event = new CustomEvent('embed-credit-monitoring', arrayEvent)
        window.parent.document.dispatchEvent(event)
    }

    return (
        <array-credit-report
            appKey={process.env.REACT_APP_ARRAY_APP_KEY}
            userToken={searchParams.get('array_user_token')}
            sandbox={process.env.REACT_APP_ARRAY_SANDBOX}
            locale="es-US"
            autoExpand="true"
            live="true"
            showLoadingMessage="true"
        />
    )
}
