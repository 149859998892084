import  { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import {
    User4Icon,
    Chat2Icon,
    Home2Icon,
    GridIcon
} from 'assets/icons_v3/fonts'

import { UserContext } from 'context/UserContext'
import { STATES_AVAILABLES } from 'constants'

export default function Menu({ selected }) {
    const { state: userState } = useContext(UserContext)

    const navigate = useNavigate()

    const MENU_LIST = [
        {
            label: 'home',
            title: 'Inicio',
            icon: <Home2Icon />,
            action: function () {
                navigate('/home')
            }
        },
        {
            label: 'products',
            title: 'Productos',
            icon: <GridIcon />,
            action: function () {
                navigate('/products')
            }
        },
        {
            label: 'help',
            title: 'Ayuda',
            icon: <Chat2Icon />,
            action: function () {
                if (userState.user.state === STATES_AVAILABLES.florida) {
                    window.open('mailto:support@kiwicredito.com', '_blank')
                } else {
                    navigate('/live-chat')
                }
            }
        },
        {
            label: 'profile',
            title: 'Perfil',
            icon: <User4Icon />,
            action: function () {
                navigate('/settings')
            }
        }
    ]

    return (
        <div className="p-4 bg-white fixed z-10 bottom-0 left-0 right-0 h-24 flex items-center justify-around text-slate-300-kiwi max-w-[450px] mx-auto sm:bottom-6 sm:rounded-bl-2xl sm:rounded-br-2xl">
            {MENU_LIST.map((menu) => (
                <div
                    className={`flex flex-col items-center w-20 cursor-pointer ${
                        selected === menu.label ? 'text-regal-blue-kiwi' : ''
                    }`}
                    key={menu.label}
                    onClick={menu.action}>
                    {menu.icon}
                    <label className="text-xs mt-1 pointer-events-none">
                        {menu.title}
                    </label>
                </div>
            ))}
        </div>
    )
}

Menu.propTypes = {
  selected: PropTypes.string,
};
