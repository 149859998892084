import ATHSVG from 'assets/logos/ath.svg';

export default function ATHMethod({profile, ...props}) {
    return (
        <div
            className="cursor-pointer w-full flex gap-4 items-center py-4 px-4 bg-gray-50-kiwi rounded-lg"
            {...props}>
            <div className="w-10 h-10 grow-0 shrink-0 rounded-full flex justify-center items-center bg-white text-regal-blue-kiwi">
                <img src={ATHSVG} alt="ATH method kiwi" />
            </div>
            <p className="font-semibold">ATH Móvil</p>
        </div>
    );
}
