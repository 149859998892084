import {UserContext} from 'context/UserContext';
import {useContext, useMemo} from 'react';

export function useIsAutopaySuspended(product = 'personal_loan') {
    const {
        state: {
            products: {
                [product]: {loan},
            },
        },
    } = useContext(UserContext);

    return useMemo(() => {
        return Boolean(loan?.has_autopay_suspended);
    }, []);
}
