import ReactPixel from "react-facebook-pixel";
import mixpanel from "utils/mixpanel";
import Emitter from "utils/emitter";
import phoneFormatter from "phone-formatter";
import * as Yup from "yup";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useState, useEffect, useContext, useRef, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { GApageView, GAevent } from "utils/GA";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { UserContext } from "context/UserContext";
import Button from "components/Button";
import Datepicker from "components/Datepicker";
import LoadingModal from "components/Modals/Loading";
import { STATES_AVAILABLES } from "constants";
import { ReferralsAPI } from "api";
import LogRocket from "logrocket";
import STATE_CONSTANTS from "constants/states";
import EmailTermsModal from "components/Modals/EmailTerms";
import ElectronicTermsModal from "components/Modals/ElectronicTerms";
import { trackEvent } from "utils/dmp_kiwi";

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const electronicTermsRef = useRef();
  const emailTermsRef = useRef();
  const form = useRef();

  const [loading, setLoading] = useState(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state: userState,
    setState: setUserState,
    registerUser,
  } = useContext(UserContext);

  const { allowedAge } = STATE_CONSTANTS(userState.location).create;

  const [showLoader, setShowLoader] = useState(false);

  const initialForm = {
    first_name: "",
    second_name: "",
    first_surname: "",
    second_surname: "",
    title: "",
    birthdate: "",
    email: "",
  };

  useEffect(() => {
    GApageView("pageview", "/create_account");

    /**
     * Si el usuario ya fue creado y se quiere regresar
     * a la creación de la cuenta...
     */
    if (userState.user.id) {
      return navigate("/login");
    }

    if (authState.cellphone === "") {
      return navigate("/login");
    }

    if (authState.code === "") {
      return navigate("/code-verification");
    }

    if (
      [STATES_AVAILABLES.puertoRico, STATES_AVAILABLES.florida].includes(
        userState.location,
      ) === false
    ) {
      sessionStorage.setItem("redirectCreateAccount", true);
      return navigate("/location");
    }

    dataLayer.push({
      event: "new_application",
      eventCategory: "register_process",
      eventAction: "register_process_personal_info",
      dl_country: userState.location,
    });
    mixpanel.track("Account Create start");
  }, []);

  const getNavigatorLang = () => {
    var userLang = navigator.language || navigator.userLanguage;
    return userLang;
  };

  const onCreateAccount = async (user) => {
    if (loading === true) {
      return;
    }

    try {
      setLoading(true);
      setShowLoader(true);

      /** Transformar objeto Date a YYYY-MM-DD */
      user.birthdate = dayjs(user.birthdate).format("YYYY-MM-DD");

      const { data } = await registerUser({
        ...user,
        phone_number: authState.cellphone,
        ip_address: null,
        source_url: Cookies.get("kw_source_url") ?? window.location.href,
        state: userState.location,
        language: getNavigatorLang(),
      });

      if (data.success === true) {
        const user = data.data;

        await new Promise((resolve) => {
          setUserState((prev) => ({
            ...prev,
            user,
          }));
          resolve();
        });

        await createdAccountEvents(user);

        if (
          window.location.origin.includes("ios") ||
          window.location.origin.includes("android")
        ) {
          return navigate("/create-account/attribution");
        }

        console.log("Create Account");

        navigate("/create-account/success");
      }
    } catch (error) {
      console.log(error);

      const { data } = error.response ?? {};

      if (data) {
        if (data.messages.includes("Email is already registered")) {
          form.current.setFieldError(
            "email",
            "El correo electrónico ya existe, por favor escribe a support@kiwicredito.com ",
          );
        } else if (
          [
            "Phone number is already registered",
            "The number is already registered in firebase",
          ].includes(data.messages)
        ) {
          Emitter.emit("onOpenNotification", {
            type: "error",
            title: "El teléfono ya registrado",
            message: `Ya existe un usuario registrado con este número, por favor inicia sesión. Error: ${data.messages}`,
          });
        } else if (data.messages.includes("INVALID_EMAIL")) {
          form.current.setFieldError(
            "email",
            "Correo electrónico no es válido",
          );
        }
      }
    } finally {
      setLoading(false);
      setShowLoader(false);
    }
  };

  const createdAccountEvents = async (user) => {
    GAevent("user_action", "create_account_success");

    mixpanel.identify(
      phoneFormatter.format(authState.cellphone, "+1 (NNN) NNN-NNNN"),
    );

    LogRocket.track("Account Created");
    ReactPixel.track("CompleteRegistration");

    window.dataLayer.push({
      event: "create_account_success",
      email: user.email,
      phone_number: phoneFormatter.format(authState.cellphone, "+1NNNNNNNNNN"),
    });

    const kw_referral_code = Cookies.get("kw_referral_code");

    if (kw_referral_code) {
      await ReferralsAPI.validateReferralCode({
        code: phoneFormatter.format(kw_referral_code, "+1 (NNN) NNN-NNNN"),
        to: user.id,
      });
    }

    mixpanel.track("Referral Code Input", {
      "Referral code": kw_referral_code ?? "No code",
    });

    mixpanel.people.set({
      "Referral Code": kw_referral_code ?? "No code",
    });

    Cookies.remove("kw_source_url");
    Cookies.remove("kw_referral_code");

    trackEvent({
      place: "/create-account",
      event_type: "button",
      user_id: user.id,
      pathname: window.location.href,
    });
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Este campo es requerido")
      .matches(/^[a-zA-Z\u00C0-\u00FF ]*$/, "Este campo solo permite letras")
      .test(
        "minName",
        "Este campo debe ser de máximo 50 letras",
        (val) => val && val.length > 0 && val.length < 51,
      ),
    second_name: Yup.string()
      .matches(/^[a-zA-Z\u00C0-\u00FF ]*$/, "Este campo solo permite letras")
      .test("minLast", "Este campo debe ser de máximo 50 letras", (val) => {
        if (val === undefined) return true;
        return val.length > 0 && val.length < 51;
      }),
    first_surname: Yup.string()
      .required("Este campo es requerido")
      .matches(/^[a-zA-Z\u00C0-\u00FF ]*$/, "Este campo solo permite letras")
      .test(
        "minName",
        "Este campo debe ser de máximo 50 letras",
        (val) => val && val.length > 0 && val.length < 51,
      ),
    second_surname: Yup.string()
      .matches(/^[a-zA-Z\u00C0-\u00FF ]*$/, "Este campo solo permite letras")
      .test("minLast", "Este campo debe ser de máximo 50 letras", (val) => {
        if (val === undefined) return true;
        return val.length > 0 && val.length < 51;
      }),
    title: Yup.string().required("Este campo es requerido"),
    birthdate: Yup.string()
      .required("Este campo es requerido")
      .test("legalAge", allowedAge.text, (val) => {
        if (val === undefined) return false;

        let currentDate = dayjs();
        let diffYears = currentDate.diff(val, "year");

        return diffYears >= allowedAge.number;
      }),
    email: Yup.string()
      .required("Este campo es requerido")
      .email("Correo electrónico inválido"),
  });

  return (
    <Fragment>
      <LoadingModal isVisible={showLoader} />

      <EmailTermsModal ref={emailTermsRef} />
      <ElectronicTermsModal ref={electronicTermsRef} />

      <Formik
        initialValues={initialForm}
        onSubmit={onCreateAccount}
        validationSchema={validationSchema}
        innerRef={form}
      >
        {({ errors, touched }) => (
          <Form className="flex flex-col grow" autoComplete="off">
            <h1 className="text-dark-kiwi font-semibold mb-10 text-2xl">
              Crear tu cuenta
            </h1>

            <div className="mb-10 flex flex-col gap-6">
              <div>
                <label
                  htmlFor="first_name"
                  className="inline-block text-sm mb-2"
                >
                  Primer nombre
                </label>
                <Field
                  id="first_name"
                  name="first_name"
                  className={`w-full rounded-lg py-2 px-4 border ${
                    errors.first_name && touched.first_name
                      ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                      : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                  }`}
                />
                <ErrorMessage
                  name="first_name"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>

              <div>
                <label
                  htmlFor="second_name"
                  className="inline-block text-sm mb-2"
                >
                  Segundo nombre{" "}
                  <span className="text-gray-400-kiwi">(opcional)</span>
                </label>
                <Field
                  id="second_name"
                  name="second_name"
                  className={`w-full rounded-lg py-2 px-4 border ${
                    errors.second_name && touched.second_name
                      ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                      : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                  }`}
                />
                <ErrorMessage
                  name="second_name"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>

              <div>
                <label
                  htmlFor="first_surname"
                  className="inline-block text-sm mb-2"
                >
                  Primer apellido
                </label>
                <Field
                  id="first_surname"
                  name="first_surname"
                  className={`w-full rounded-lg py-2 px-4 border ${
                    errors.first_surname && touched.first_surname
                      ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                      : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                  }`}
                />
                <ErrorMessage
                  name="first_surname"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>

              <div>
                <label
                  htmlFor="second_surname"
                  className="inline-block text-sm mb-2"
                >
                  Segundo apellido{" "}
                  <span className="text-gray-400-kiwi">(opcional)</span>
                </label>
                <Field
                  id="second_surname"
                  name="second_surname"
                  className={`w-full rounded-lg py-2 px-4 border ${
                    errors.second_surname && touched.second_surname
                      ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                      : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                  }`}
                />
                <ErrorMessage
                  name="second_surname"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>

              <div>
                <label htmlFor="title" className="inline-block text-sm mb-2">
                  Titulo
                </label>
                <Field
                  as="select"
                  name="title"
                  id="title"
                  className={`w-full rounded-lg py-2 px-4 border ${
                    errors.title && touched.title
                      ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                      : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                  }`}
                >
                  <option defaultValue hidden></option>
                  <option>Sr</option>
                  <option>Sra</option>
                </Field>
                <ErrorMessage
                  name="title"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>

              <div>
                <label
                  htmlFor="birthdate"
                  className="inline-block text-sm mb-2"
                >
                  Fecha de nacimiento
                </label>
                <Field name="birthdate" component={Datepicker} />
                <ErrorMessage
                  name="birthdate"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>

              <div>
                <label htmlFor="email" className="inline-block text-sm mb-2">
                  Correo electrónico
                </label>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  placeholder="info@mail.com"
                  className={`w-full rounded-lg py-2 px-4 border ${
                    errors.email && touched.email
                      ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                      : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="small"
                  className="text-red-kiwi inline-block text-xs w-full mt-2"
                />
              </div>
            </div>

            <div className="text-slate-500-kiwi text-xs mt-auto">
              Al continuar, estás de acuerdo con nuestro{" "}
              <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => emailTermsRef.current.openModal()}
              >
                Consentimiento de Comunicación Email 12 CFR S1041
              </span>{" "}
              y{" "}
              <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => electronicTermsRef.current.openModal()}
              >
                Consentimiento Comunicación Electrónica 15 U.S.C S700
              </span>
            </div>

            <Button
              id="create_account_success-gtm"
              className={"w-full rounded-xl p-3 mt-6 bg-blue-kiwi text-white"}
              type="submit"
              loading={loading}
            >
              Continuar
            </Button>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
