import {
    GiftIcon,
    MedalIcon,
    QuestionIcon
} from 'assets/icons_v3/fonts'
import BackwardButton from 'components/Button/Backward'
import RouletteHowWorks from 'components/Modals/RouletteHowWorks'
import RouletteTermsModal from 'components/Modals/RouletteTerms'
import RouletteComponent from 'components/Roulette'
import  { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function RouletteWheel() {
    const navigate = useNavigate()
    const [policyTermsVisible, setPolicyTermsVisible] = useState(false)
    const [howWorksVisible, setHowWorksVisible] = useState(false)

    const data = [
        {
            option: '',
            style: { backgroundColor: '#33CAFF' }
        },
        {
            winner: true,
            option: '$300',
            style: {
                backgroundColor: '#022E56',
                textColor: '#FFFFFF'
            }
        },
        { option: '', style: { backgroundColor: '#FFFFFF' } },
        { option: '', style: { backgroundColor: '#33CAFF' } },
        {
            option: '',
            style: {
                backgroundColor: '#022E56',
                textColor: '#FFFFFF'
            }
        },
        { option: '', style: { backgroundColor: '#33CAFF' } },
        {
            winner: true,
            option: '$3,000',
            style: {
                backgroundColor: '#FFFFFF',
                textColor: '#011E3D'
            }
        },
        { option: '', style: { backgroundColor: '#33CAFF' } },
        {
            option: '',
            style: {
                backgroundColor: '#022E56',
                textColor: '#FFFFFF'
            }
        },
        { option: '', style: { backgroundColor: '#FFFFFF' } },
        { option: '', style: { backgroundColor: '#33CAFF' } },
        {
            winner: true,
            option: '$500',
            style: { backgroundColor: '#FFFFFF', textColor: '#011E3D' }
        }
    ]

    const onBackward = () => {
        navigate('/home')
    }

    const onTogglePolicyTerms = () => {
        setPolicyTermsVisible((visible) => !visible)
    }

    const onToggleHowWorks = () => {
        setHowWorksVisible((visible) => !visible)
    }

    const onPrizes = () => {
        navigate('/roulette/prizes')
    }

    const onWinners = () => {
        navigate('/roulette/winners')
    }

    const onHowWorks = () => {
        navigate('/roulette/how-works')
    }

    return (
        <Fragment>
            <div className="mb-12 flex justify-between">
                <BackwardButton onBackward={onBackward} />

                <div onClick={onToggleHowWorks} className="cursor-pointer ">
                    <QuestionIcon />
                </div>
            </div>

            <div className="mb-10">
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    ¡Gira la ruleta y gana!
                </h1>

                <div className="mt-4">
                    Únete cada jueves en nuestro Facebook live y gana increíbles
                    premios
                </div>
            </div>

            <div className="rounded-xl flex flex-col mt-auto">
                <div className="relative">
                    <RouletteComponent data={data} />
                    <div className="absolute bg-regal-blue-kiwi h-1/2 -bottom-0.5 w-full rounded-tl-xl rounded-tr-xl"></div>
                </div>

                <div className="flex p-6 rounded-bl-xl rounded-br-xl justify-around bg-regal-blue-kiwi text-white">
                    <button
                        className="flex flex-col items-center cursor-pointer"
                        onClick={onPrizes}>
                        <div className="flex h-12 w-12 rounded-xl bg-white bg-opacity-20">
                            <GiftIcon className="w-6 h-6 text-white m-auto" />
                        </div>
                        <div className="text-xs mt-2 w-20 text-center">
                            Premios de la semana
                        </div>
                    </button>
                    <button
                        className="flex flex-col items-center cursor-pointer"
                        onClick={onWinners}>
                        <div className="flex h-12 w-12 rounded-xl bg-white bg-opacity-20">
                            <MedalIcon className="w-6 h-6 text-white m-auto" />
                        </div>
                        <div className="text-xs mt-2 w-20 text-center">
                            Ganadores semanales
                        </div>
                    </button>
                    <button
                        className="flex flex-col items-center cursor-pointer"
                        onClick={onHowWorks}>
                        <div className="flex h-12 w-12 rounded-xl bg-white bg-opacity-20">
                            <QuestionIcon className="w-6 h-6 text-white m-auto" />
                        </div>
                        <div className="text-xs mt-2 w-20 text-center">
                            Cómo participar
                        </div>
                    </button>
                </div>
            </div>

            <div className="text-xs text-gray-500-kiwi mt-10">
                Al participar en nuestra ruleta, confirmo que he leído y estoy
                de acuerdo con el{' '}
                <span
                    className="text-blue-kiwi underline cursor-pointer"
                    onClick={onTogglePolicyTerms}>
                    Términos y condiciones
                </span>{' '}
                de la Ruleta de premios Kiwi.
            </div>

            <RouletteHowWorks
                isVisible={howWorksVisible}
                closeModal={onToggleHowWorks}
            />

            <RouletteTermsModal
                isVisible={policyTermsVisible}
                closeModal={onTogglePolicyTerms}
            />
        </Fragment>
    )
}
