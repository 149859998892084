import ReactPixel from "react-facebook-pixel";
import { useState, useEffect, Fragment, useContext, useRef } from "react";
import { Formik, Form, Field } from "formik";
import phoneFormatter from "phone-formatter";
import { useNavigate } from "react-router-dom";
import { RequestLoanContext } from "context/RequestLoanContext";
import { UserContext } from "context/UserContext";
import mixpanel from "utils/mixpanel";
import { GAevent } from "utils/GA";
import { AuthApi } from "api";

import LoadingModal from "components/Modals/Loading";
import ProgressBar from "components/ProgressBar";
import AmountHomeOwnershipModal from "components/Modals/AmountHomeOwnership";
import { DQ_STATUS } from "constants";
import AmountHomeOtherOwnershipModal from "components/Modals/AmountHomeOtherOwnership";
import { UsersAPI, ApplicationAPI } from "api";
import { trackEvent } from "utils/dmp_kiwi";

export default function RequestLoanResidence() {
  const navigate = useNavigate();
  const form = useRef(null);

  const {
    state: requestState,
    saveState: saveRequestState,
    createSurvey,
  } = useContext(RequestLoanContext);

  const {
    state: userState,
    setState: setUserState,
    validateDQRules,
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showOwnershipModal, setShowOwnershipModal] = useState(false);
  const [showOtherOwnershipModal, setShowOtherOwnershipModal] = useState(false);
  const [typeOwnership, setTypeOwnership] = useState("");

  useEffect(() => {
    if (requestState.currentLoan.completed === false) {
      return navigate("/request-loan/current-loan");
    }
  }, []);

  const onCreateSurvey = async (option, amount, otherOption) => {
    if (loading === true) {
      return;
    }

    try {
      setShowLoader(true);
      setLoading(true);

      const {
        data: {
          data: { firebase_id },
        },
      } = await AuthApi.getUserFirebaseID();

      await createSurvey({
        home_ownership: option,
        home_ownership_other: otherOption ?? null,
        amount_home_ownership: amount,
        firebase_id: firebase_id,
        monthly_income: requestState.income.value,
        income_direct: false,
        employment_situation: requestState.employment.value,
        militar: requestState.military.value.includes("Si") ? true : false,
        customer_employer: requestState.employer.value,
        months_receiving_income: requestState.seniority.value,
        bankruptcy: requestState.bankruptcy.value === "Si" ? true : false,
        currentLoan: requestState.currentLoan.value === "Si" ? true : false,
      });

      trackUserEvents(option, amount);

      await MLAcheck(option);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setShowLoader(false);
    }
  };

  const trackUserEvents = async (residence, amount) => {
    mixpanel.track("Application in Review");
    GAevent("user_action", "loan_ask");
    window.dataLayer.push({
      event: "loan_ask",
      email: userState.user.email,
      phone_number: phoneFormatter.format(
        userState.user.phone_number,
        "+1NNNNNNNNNN",
      ),
      formid: 26,
      uniqueEventId: 3,
      inputs: [
        {
          name: "name",
          value: `${userState.user.first_name} ${userState.user.last_name}`,
        },
        { name: "email", value: userState.user.email },
        { name: "phone", value: userState.user.phone_number },
      ],
    });

    ReactPixel.track("AddToCart", {
      content_name: "loan_ask",
      content_category: "",
      contents: [
        {
          id: userState.user.id,
          quantity: 1,
        },
      ],
    });

    mixpanel.track("Primary Residence Survey", {
      Residence: residence,
      Amount: amount,
    });

    trackEvent({
      place: "/request-loan/residence",
      event_type: "button",
      pathname: window.location.href,
      user_id: userState.user.id,
    });
  };
  const MLAcheck = async (residence) => {
    try {
      await UsersAPI.mlaCheckSurvey();
      await validateDQRedirect(residence);
    } catch (error) {
      if (error?.response?.data?.data.includes("check-mla-fail")) {
        saveRequestState({
          ...requestState,
          residence: {
            value: residence,
            completed: true,
          },
        });
        navigate("/request-loan/reviewing-mla");
      } else {
        await validateDQRedirect(residence);
      }
    }
  };

  const handleEvaluetaRenewal = async () => {
    try {
      await ApplicationAPI.evaluetaRenewal();
    } catch (error) {
      console.error("error_evaluetaRenewal:", error);
    }
  };

  const validateDQRedirect = async (residence) => {
    const {
      user: { id },
    } = userState;

    const {
      data: { data: status },
    } = await validateDQRules(id);

    await handleEvaluetaRenewal();

    setUserState((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        personal_loan: {
          ...prev.products.personal_loan,
          dq: {
            status,
          },
        },
      },
    }));

    saveRequestState({
      ...requestState,
      residence: {
        value: residence,
        completed: true,
      },
    });

    dataLayer.push({
      event: "new_application",
      eventCategory: "loan_process",
      eventAction: "loan_process_review",
      dl_user_id: userState.user.id,
      dl_main_residence: residence,
    });

    if (
      [DQ_STATUS.soft, DQ_STATUS.approved, DQ_STATUS.connectionFail].includes(
        status,
      )
    ) {
      return navigate("/request-loan/validation");
    }

    if ([DQ_STATUS.hard].includes(status)) {
      return navigate("/request-loan/reviewing");
    }
  };

  const onBackward = () => {
    navigate("/request-loan/current-loan");
  };

  const toggleOwnershipModal = () => {
    setShowOwnershipModal(!showOwnershipModal);
  };

  const toggleOtherOwnershipModal = () => {
    setShowOtherOwnershipModal(!showOtherOwnershipModal);
  };

  const onCloseOwnershipModal = () => {
    setTypeOwnership("");
    toggleOwnershipModal();
    form.current.resetForm();
  };
  const onCloseOtherOwnershipModal = () => {
    setTypeOwnership("");
    toggleOtherOwnershipModal();
    form.current.resetForm();
  };

  const onChangeOwnershipAmount = (amount) => {
    onCreateSurvey(typeOwnership, amount);
  };

  const onChangeOtherOwnershipData = (amount, typeResidence) => {
    onCreateSurvey(typeOwnership, amount, typeResidence);
  };

  const onSelectOption = (option) => {
    setTypeOwnership(option);

    if (["Hipoteca", "Alquiler"].includes(option)) {
      return toggleOwnershipModal();
    }

    if (["Otro"].includes(option)) {
      return toggleOtherOwnershipModal();
    }

    onCreateSurvey(option);
  };

  const RESIDENCE_LIST = [
    "Alquiler",
    "Hipoteca",
    "Propietario",
    "Vivienda subsidiada",
    "Otro",
  ];

  const initialValues = {
    value: "",
  };

  return (
    <Formik initialValues={initialValues} innerRef={form}>
      {() => (
        <Fragment>
          <LoadingModal isVisible={showLoader} />

          <ProgressBar progress="11/12" onBackward={onBackward} />

          <AmountHomeOwnershipModal
            changeAmount={onChangeOwnershipAmount}
            isVisible={showOwnershipModal}
            type={typeOwnership}
            closeModal={onCloseOwnershipModal}
          />

          <AmountHomeOtherOwnershipModal
            changeOtherData={onChangeOtherOwnershipData}
            isVisible={showOtherOwnershipModal}
            closeModal={onCloseOtherOwnershipModal}
          />

          <Form className="flex flex-col grow" autoComplete="off">
            <h1 className="text-dark-kiwi font-semibold text-2xl">
              Selecciona tu situación de residencia principal
            </h1>

            <div className="flex flex-col gap-6 mb-10 mt-8">
              {RESIDENCE_LIST.map((item) => (
                <div className="relative" key={item}>
                  <Field
                    className="peer hidden"
                    type="radio"
                    id={item}
                    name="value"
                    value={item}
                    onClick={() => onSelectOption(item)}
                  />
                  <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                  <label
                    htmlFor={item}
                    className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4"
                  >
                    {item}
                  </label>
                </div>
              ))}
            </div>
          </Form>
        </Fragment>
      )}
    </Formik>
  );
}
