import RequestLoan from './RequestLoan';
import ProgressLoan from './ProgressLoan';
import RejectedLoan from './RejectedLoan';
import {usePersonalLoanStatus} from 'hooks/personalLoan';

export default function HomeStatus({className}) {
    const {
        isPersonalLoanApply,
        isPersonalLoanReviewing,
        isPersonalLoanApproved,
        isPersonalLoanRejected,
        isPersonalLoanActive,
    } = usePersonalLoanStatus();

    const RenderStatusCard = () => {
        if (isPersonalLoanApply) {
            return <RequestLoan />;
        }

        if (
            isPersonalLoanReviewing ||
            isPersonalLoanApproved ||
            isPersonalLoanActive
        ) {
            return <ProgressLoan />;
        }

        if (isPersonalLoanRejected) {
            return <RejectedLoan />;
        }
    };

    return (
        <div className={className}>
            <RenderStatusCard />
        </div>
    );
}
