import BaseModal from "./../BaseModal";
import PropTypes from "prop-types";

import PaymentMethod from "components/PaymentMethod";
import ATHMethod from "components/ATHMethod";

const OnePayMethodModal = ({
  isVisible,
  closeModal,
  sendCard,
  paymentMethods,
  onAddNewCard,
  hasMoreCreditCards,
  onATHMethod,
}) => {
  const isATHEnable = process.env.REACT_APP_ATH_ENABLE === "true";

  return (
    <BaseModal
      title="Selecciona tarjeta"
      isVisible={isVisible}
      closeModal={closeModal}
    >
      <div className="flex flex-col mt-4">
        <div className="flex flex-col gap-4 ">
          {paymentMethods?.map((profile, index) => (
            <PaymentMethod key={index} profile={profile} onClick={sendCard} />
          ))}
          {onATHMethod && isATHEnable && <ATHMethod onClick={onATHMethod} />}
        </div>

        {!hasMoreCreditCards && (
          <div
            onClick={onAddNewCard}
            className="mt-10 mx-auto cursor-pointer text-blue-kiwi inline-block"
          >
            Agrega nueva tarjeta
          </div>
        )}
      </div>
    </BaseModal>
  );
};

OnePayMethodModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendCard: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  onAddNewCard: PropTypes.func.isRequired,
  hasMoreCreditCards: PropTypes.bool.isRequired,
};

export default OnePayMethodModal;
