import { useRef, useEffect, useContext } from "react";
import { trackEvent } from "utils/dmp_kiwi";
import { UserContext } from "context/UserContext";
import { useLocation } from "react-router-dom";

export const TrackingProvider = () => {
  const location = useLocation();
  const previousLocation = useRef(null);
  const pageReferrer = document.referrer;

  const { state: userState } = useContext(UserContext);
  const user_id = userState?.user?.id;

  const generateUrl = (path) => {
    if (!path) return window.location.origin;
    return new URL(path, window.location.origin).href;
  };

  const sendPageViewEvent = async (currentPath, previousPath) => {
    await trackEvent({
      pathname: generateUrl(currentPath),
      place: previousPath || pageReferrer,
      event_type: "page_view",
      user_id,
    });
  };

  useEffect(() => {
    const pathname = location.pathname;

    sendPageViewEvent(pathname, previousLocation.current);
    previousLocation.current = location.pathname;
  }, [location]);

  return null;
};
