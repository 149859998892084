import { useEffect, useContext, useState, Fragment } from "react";
import { UserContext } from "context/UserContext";
import PropTypes from "prop-types";
import PersonaVerification from "./Verification";
import PersonaWaiting from "./Waiting";
import { PERSONA_STATUS } from "constants";
import { AuthContext } from "context/AuthContext";
import LoadingModal from "components/Modals/Loading";
import { useNavigate } from "react-router-dom";

export default function PersonaTemplate({
  template,
  inquiryTag,
  context,
  onBackward,
  onContinue,
}) {
  const navigate = useNavigate();
  const {
    state: userState,
    updateUser,
    addTagInquiryId,
    getSessionTokenInquiryId,
  } = useContext(UserContext);
  const { state: currentState, saveState: saveCurrentState } =
    useContext(context);
  const { state: authState } = useContext(AuthContext);
  const [showWaiting, setShowWaiting] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showVerificationMobile, setShowVerificationMobile] = useState(false);
  const [showLoaderMobile, setShowLoaderMobile] = useState(true);

  useEffect(() => {
    personaStatus();
  }, []);

  const personaStatus = () => {
    const status = [null, "", PERSONA_STATUS.retry].includes(
      userState?.user?.inquiry_status,
    );

    if (!status) {
      if (
        window.location.origin.includes("ios") ||
        window.location.origin.includes("android") ||
        window.location.origin.includes("qa-mobile")
      ) {
        navigate(`/approved-loan/persona-waiting?inquiry_tag=${inquiryTag}`);
        return;
      }

      setShowWaiting(true);
      return;
    }

    if (
      window.location.origin.includes("ios") ||
      window.location.origin.includes("android") ||
      window.location.origin.includes("qa-mobile")
    ) {
      setShowVerificationMobile(true);
      getDateByMobilePersona();
      return;
    }

    setShowVerification(true);
  };

  const getSessionToken = async () => {
    const { id } = userState?.user;
    const {
      data: { data },
    } = await getSessionTokenInquiryId(id);

    return data;
  };

  const getDateByMobilePersona = async () => {
    setShowLoaderMobile(true);
    const { id: inquiryId, sessionToken } = await getSessionToken();
    const { id: referenceId, phone_number, email, zipcode } = userState?.user;

    const data = {
      inquiry_id: inquiryId,
      session_token: sessionToken,
      reference_id: referenceId,
      inquiry_tag: inquiryTag,
      template_id: inquiryId ? null : template,
      environmentId: process.env.REACT_APP_PERSONA_ENV,
      fields: {
        api_address_postal_code: zipcode,
        addressPostalCode: zipcode,
        phoneNumber: phone_number,
        emailAddress: email,
      },
      authentication: {
        auth_token: authState?.accessTokenApp,
      },
    };

    setShowLoaderMobile(false);
    navigate(`/approved-loan/persona?data=${encodeURI(JSON.stringify(data))}`);
  };

  const onVerification = async (inquiry_id) => {
    if (inquiry_id) {
      await updateUser({ inquiry_id });
      await addTagInquiryId({
        inquiry_id,
        tag: inquiryTag,
      });
    }

    setShowVerification(false);
    setShowWaiting(true);
  };

  const onApproved = async () => {
    saveCurrentState({
      ...currentState,
      persona: {
        completed: true,
      },
    });

    onContinue();
  };

  return (
    <Fragment>
      {showVerification && (
        <PersonaVerification
          template={template}
          onBackward={onBackward}
          onVerification={onVerification}
        />
      )}

      {showVerificationMobile && (
        <>
          <LoadingModal isVisible={showLoaderMobile} />

          <div className="flex flex-col items-center justify-center justify-self-stretch flex-1 text-center">
            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
              Validación de identidad
            </h1>
            <p>
              Espera unos momentos, estamos conectandonos con Persona para
              verificar tu identidad...
            </p>
          </div>
        </>
      )}

      {showWaiting && (
        <PersonaWaiting
          inquiryTag={inquiryTag}
          onApproved={onApproved}
          onBackward={onBackward}
        />
      )}
    </Fragment>
  );
}

PersonaTemplate.propTypes = {
  template: PropTypes.string.isRequired,
  inquiryTag: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  onBackward: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};
