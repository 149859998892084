import MoneySVG from 'assets/icons_v3/money.svg';
import {ArrowRightIcon} from 'assets/icons_v3/fonts';
import {
    usePersonalLoanRedirect,
    usePersonalLoanStatus,
} from 'hooks/personalLoan';

export default function HomeStatusApply() {
    const {personalLoanContext} = usePersonalLoanStatus();
    const personalLoanRedirect = usePersonalLoanRedirect();
    const requestLoanHasTracking = Object.keys(personalLoanContext).length > 0;

    return (
        <div className="p-4 rounded-xl bg-slate-100-kiwi flex items-start gap-4">
            <img src={MoneySVG} className="w-10 shrink-0" />
            <div className="flex flex-col">
                <h3 className="font-semibold text-dark-kiwi mb-1">
                    ¿Necesitas dinero?
                </h3>
                <p className="text-xs mb-4 text-slate-700-kiwi">
                    Solicita un préstamo personal de hasta $1,000 ¡Ahora mismo!
                </p>
                <div
                    onClick={personalLoanRedirect}
                    className="rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex items-center self-start cursor-pointer">
                    {requestLoanHasTracking
                        ? 'Continuar solicitud'
                        : 'Solicitar ahora'}{' '}
                    <ArrowRightIcon className="w-5 ml-1" />
                </div>
            </div>
        </div>
    );
}
