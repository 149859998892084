import banks from 'data/banks/FL';
import CITIES from 'data/cities';

export default {
    create: {
        allowedAge: {
            text: 'Debes tener 18 años para poder registrarte',
            number: 18,
        },
    },
    home: {
        creditBuilderAvailable: false,
        creditMonitoringAvailable: true,
    },
    address: {
        cities: CITIES.FL,
    },
    approved: {
        banks,
        termsMonths() {
            return 6;
        },
        daysOptionsList: [14, 15, 16, 17, 18, 19, 20],
        lastDayOption: false,
        onCalculateInstallment(loanAmount, interestRate, months) {
            const monthlyInterestRate = interestRate / 100 / 12;

            const biweeklyInterestRate =
                Math.pow(1 + monthlyInterestRate, 1 / 2) - 1;

            const biweeklyPayment =
                (loanAmount * biweeklyInterestRate) /
                (1 - Math.pow(1 + biweeklyInterestRate, -months));

            return Number(biweeklyPayment.toFixed(2));
        },
        paymentFrequency: 'bisemanal',
        personaTemplate: 'itmpl_gotk9pToMH4w5vdcgMrM34E5mnvj',
    },
};
