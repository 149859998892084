
import PropTypes from 'prop-types'

const Button = ({
    className,
    onClick,
    children,
    loading = false,
    ...props
}) => {
    const onHandleClick = () => {
        if (loading === false && onClick) {
            onClick()
        }
    }

    const showContent = () => {
        if (loading === true) {
            return (
                <span>
                    <b></b>
                    <b></b>
                    <b></b>
                </span>
            )
        }

        return children
    }

    return (
        <button
            disabled={loading}
            className={`button flex justify-center items-center h-12 ${className}`}
            onClick={onHandleClick}
            {...props}>
            {showContent()}
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool
}

export default Button
