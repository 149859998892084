import React from "react";
import { useNavigate } from "react-router-dom";

import WarningSVG from "assets/onboarding_v3/warning.svg";
import BackwardButton from "components/Button/Backward";

export default function BlackList() {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate("/home");
  };

  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <BackwardButton onBackward={onBackward} className="mb-0" />

      <div className="flex flex-col justify-center text-center m-auto">
        <img
          className="mx-auto mb-10"
          width="200"
          src={WarningSVG}
          alt="kiwi-image"
        />
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Tu cuenta está inhabilitada
        </h1>
        <p>
          Si requieres más detalles, puedes contactarte con nuestro equipo de
          soporte enviándonos un correo a{" "}
          <a className="font-semibold" href="mailto:support@kiwicredito.com">
            support@kiwicredito.com
          </a>{" "}
          para más información y asistencia.
        </p>
      </div>
    </div>
  );
}
