import mixpanel from 'utils/mixpanel';
import  {
    useState,
    Fragment,
    useContext,
    useLayoutEffect,
    useEffect,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';

import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import {StarIcon} from '@heroicons/react/solid';
import {UserContext} from 'context/UserContext';
import {STATES_AVAILABLES} from 'constants';
import stackMoney from 'assets/icons/stack-money.svg';
import {ArrowRightIcon} from 'assets/icons_v3/fonts';
import {UsersAPI} from 'api';

export default function RequestLoanValidation() {
    const navigate = useNavigate();

    const {state: requestState, saveState} = useContext(RequestLoanContext);
    const {state: userState} = useContext(UserContext);

    const [showGoodClientBanner, setShowGoodClientBanner] = useState(false);

    const [validationType, setValidationType] = useState('');

    useLayoutEffect(() => {
        if (requestState.residence.completed === false) {
            return navigate('/request-loan/residence');
        }

        validateFloridaState();
    });

    const validateFloridaState = () => {
        if (userState.user.state === STATES_AVAILABLES.florida) {
            setValidationType('plaid');
            onContinue();
        }
    };

    useEffect(() => {
        isGoodClient();
    }, []);

    const onContinue = () => {
        if (validationType === '') {
            return;
        }

        if (validationType === 'plaid') {
            navigate('/request-loan/plaid');
            _cio.track('link_bank_account', {
                status: 'start_plaid',
            });
            mixpanel.track('Link Bank Account Start');
        } else if (validationType === 'statements') {
            navigate('/request-loan/bank-statements');
            _cio.track('link_bank_account', {
                status: 'send_bank_statements',
            });
            mixpanel.track('Send Bank Statements');
        }

        saveState({
            ...requestState,
            validation: {
                value: validationType,
                completed: true,
            },
        });
    };

    

    const onBackward = () => {
        navigate('/home');
    };

    const onSelectValidationType = validationType => {
        setValidationType(validationType);
    };

    const isMobile = () => {
        return ['android.kiwicredito.com', 'ios.kiwicredito.com'].includes(
            window.location.host,
        );
    };

    const isGoodClient = async () => {
        try {
            const {user} = userState;

            const {data: {data: {is_good_client}}} = await UsersAPI.getGoodClientBannerValidation(  
                user?.id,  
            );  
            setShowGoodClientBanner(is_good_client);
        } catch {
            setShowGoodClientBanner(false);
        }
    };

    const onClickGoodClientBanner = () => {
        setValidationType('plaid');
        navigate('/request-loan/plaid');
    };

    return (
        <Fragment>
            <ProgressBar progress="12/12" onBackward={onBackward} />

            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Tu solicitud está siendo revisada
                </h1>
                <p className="mb-8">
                    Recibirás respuesta en al menos 12 horas laborables.
                </p>

                {showGoodClientBanner && (
                    <div onClick={onClickGoodClientBanner} className="w-auto h-28 rounded-xl bg-blue-kiwi/10 p-4 flex gap-4 mb-8">
                        <img className="w-8 h-8" src={stackMoney} alt="" />
                        <div>
                            <h4 className=" text-slate-900-kiwi text-sm font-semibold mb-1">
                                ¡Preaprobado para hasta $2,000!
                            </h4>
                            <p className="text-slate-900-kiwi text-xs font-normal mb-2">
                                Verifica tus ingresos ahora para solicitar un
                                monto de préstamo mayor.
                            </p>
                            <div className="flex items-center gap-2 cursor-pointer">
                                <p className=" text-blue-kiwi text-xs underline font-medium">
                                    Verificar ingresos
                                </p>
                                <ArrowRightIcon className="w-4 h-4 text-blue-kiwi" />
                            </div>
                        </div>
                    </div>
                )}

                <p className="mb-10 text-gray-500-kiwi text-sm">
                    Sugerimos que completes uno de los pasos abajo para validar
                    tus ingresos y aumentar tu probabilidad de aprobación:
                </p>

                <div className="flex flex-col gap-6">
                    <div className="relative">
                        <input
                            className="peer hidden"
                            type="radio"
                            id="linkAccount"
                            name="bank"
                            onChange={() => onSelectValidationType('plaid')}
                            checked={validationType === 'plaid'}
                        />
                        <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                        <label
                            htmlFor="linkAccount"
                            className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                            <div className="leading-5 mb-1">
                                Enlazar cuenta de banco
                            </div>
                            <p className="flex items-center text-xs text-gray-500-kiwi">
                                Automático
                                <span className="flex ml-2 bg-yellow-kiwi text-white px-4 py-0.5 rounded-lg">
                                    <StarIcon className="w-4 mr-1" />
                                    Recomendado
                                </span>
                            </p>
                        </label>
                    </div>
                    {isMobile() ? (
                        ''
                    ) : (
                        <div className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id="sendStatement"
                                name="bank"
                                onChange={() =>
                                    onSelectValidationType('statements')
                                }
                                checked={validationType === 'statements'}
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor="sendStatement"
                                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                <div className="leading-4 mb-1">
                                    Enviar estados bancarios
                                </div>
                                <p className="text-xs text-gray-500-kiwi">
                                    Tomará de 1 - 2 días laborables
                                </p>
                            </label>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-col mt-auto">
                <Button
                    className={`rounded-xl p-3 ${
                        validationType === ''
                            ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                            : 'bg-blue-kiwi text-white'
                    }`}
                    onClick={onContinue}>
                    Continuar
                </Button>
            </div>
        </Fragment>
    );
}
