import 'core-js/actual'
import * as Sentry from '@sentry/react'

import { BrowserTracing } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import {
    FpjsProvider,
    FingerprintJSPro
} from '@fingerprintjs/fingerprintjs-pro-react'

import './utils/mixpanel'
import './index.css'
import App from './App'

const FingerprintApiKey = process.env.REACT_APP_FINGERPRINT_API_KEY;

Sentry.init({
    dsn: 'https://f6fbf30e5d434ac8aa5f20ef0a70d591@o1314151.ingest.sentry.io/6609527',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
})

createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <FpjsProvider
            loadOptions={{
                apiKey: FingerprintApiKey,
                endpoint: [
                    'https://metrics.kiwicredito.com/8gH6nNkbHeBd3rzu/5dTOhnUZftad2qk0',
                    FingerprintJSPro.defaultEndpoint
                ],
                scriptUrlPattern: [
                    'https://metrics.kiwicredito.com/8gH6nNkbHeBd3rzu/jZ8HnpZXZnLI0GZt?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
                    FingerprintJSPro.defaultScriptUrlPattern
                ]
            }}>
            <App />
        </FpjsProvider>
    </BrowserRouter>
)
