import mixpanel from "utils/mixpanel";
import { useContext, useEffect } from "react";
import { UserContext } from "context/UserContext";

import Header from "./Header";
import Status from "./Status";
import StatusActive from "./Status/Active";
import Products from "./Products";
import Recommended from "./Recommended";

import Menu from "templates/Menu";
import { useKiwiPayStatus } from "hooks/kiwiPay";
import { useCreditBuilderStatus } from "hooks/creditBuilder";
import { usePersonalLoanStatus } from "hooks/personalLoan";

export default function HomeStatusCurrentLoan() {
  const { state: userState } = useContext(UserContext);

  const { isPersonalLoanActive, isSecondMoneyOnTheWay } =
    usePersonalLoanStatus();
  const { isCreditBuilderActive } = useCreditBuilderStatus();
  const { isKiwiPayActive } = useKiwiPayStatus();

  const personalLoanActive = isPersonalLoanActive && !isSecondMoneyOnTheWay;

  useEffect(() => {
    mixpanel.people.set({
      $first_name: userState.user.first_name,
      $last_name: userState.user.first_surname,
      $email: userState.user.email,
      Birthdate: userState.user.birthdate,
      $phone: userState.user.phone_number,
    });

    _cio.identify({
      id: userState.user.id,
    });

    const isNewUser = sessionStorage.getItem("isNewUser");
    if (isNewUser) {
      dataLayer.push({
        event: "new_application",
        eventCategory: "register_process",
        eventAction: "register_process_end_panel",
        dl_user_id: userState.user.id,
      });
      sessionStorage.removeItem("isNewUser");
      return;
    }
    dataLayer.push({
      event: "recurrent_application",
      eventCategory: "login",
      eventAction: "login_panel",
      dl_user_id: userState.user.id,
    });
    sessionStorage.removeItem("isNewUser");
  }, []);

  return (
    <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
      <Header className="mb-8" />
      <div className="mb-8 flex flex-col gap-6">
        {!personalLoanActive && <Status />}

        {(personalLoanActive || isCreditBuilderActive || isKiwiPayActive) && (
          <StatusActive />
        )}
      </div>
      <Products className="mb-8" />
      <Recommended />
      <Menu selected="home" />
    </div>
  );
}
