import React from 'react';
import {Route} from 'react-router-dom';

/**
 * Context Providers
 */
import {KiwiPayProvider} from 'context/KiwiPayContext';

/**
 * Routes
 */
import KiwiPay from 'views/mobile/KiwiPay';

import KiwiPayActiveLoan from 'views/mobile/KiwiPay/ActiveLoan';
import KiwiPayActiveDisbursement from 'views/mobile/KiwiPay/ActiveLoan/Disbursement';
import KiwiPayActiveHistoryPayment from 'views/mobile/KiwiPay/ActiveLoan/HistoryPayment';
import KiwiPayActiveLoansDisbursed from 'views/mobile/KiwiPay/ActiveLoan/LoansDisbursed';
import KiwiPayActiveLoansDisbursedContract from 'views/mobile/KiwiPay/ActiveLoan/LoansDisbursedContract';
import KiwiPayActivePayFee from 'views/mobile/KiwiPay/ActiveLoan/PayFee';
import KiwiPayActiveMakePayment from 'views/mobile/KiwiPay/ActiveLoan/MakePayment';
import KiwiPayActivePaymentFailed from 'views/mobile/KiwiPay/ActiveLoan/PaymentFailed';
import KiwiPayActivePaymentSuccess from 'views/mobile/KiwiPay/ActiveLoan/PaymentSuccess';
import KiwiPayActivePaymentMethod from 'views/mobile/KiwiPay/ActiveLoan/PaymentMethod';
import KiwiPayActivePaymentMethodCard from 'views/mobile/KiwiPay/ActiveLoan/PaymentMethodCard';
import KiwiPayActivePaymentMethodSuccess from 'views/mobile/KiwiPay/ActiveLoan/PaymentMethodSuccess';
import KiwiPayActivePaymentMethodRejected from 'views/mobile/KiwiPay/ActiveLoan/PaymentMethodRejected';
import KiwiPayActivePaymentMethodDetails from 'views/mobile/KiwiPay/ActiveLoan/PaymentMethodDetails';
import KiwiPayActiveMovePaymentDay from 'views/mobile/KiwiPay/ActiveLoan/MovePaymentDay';

const KiwiPayRoutes = (
    <Route
        exact
        path="kiwi-pay"
        element={
            <KiwiPayProvider>
                <KiwiPay />
            </KiwiPayProvider>
        }>
        <Route exact path="active" element={<KiwiPayActiveLoan />}>
            <Route exact path="" element={<KiwiPayActiveDisbursement />} />
            <Route
                exact
                path="history-payment"
                element={<KiwiPayActiveHistoryPayment />}
            />
            <Route
                exact
                path="loans-disbursed"
                element={<KiwiPayActiveLoansDisbursed />}
            />
            <Route
                exact
                path="loans-disbursed/contract"
                element={<KiwiPayActiveLoansDisbursedContract />}
            />
            <Route exact path="pay-fee" element={<KiwiPayActivePayFee />} />
            <Route
                exact
                path="make-payment"
                element={<KiwiPayActiveMakePayment />}
            />
            <Route
                exact
                path="payment-failed"
                element={<KiwiPayActivePaymentFailed />}
            />
            <Route
                exact
                path="payment-success"
                element={<KiwiPayActivePaymentSuccess />}
            />
            <Route
                exact
                path="payment-method"
                element={<KiwiPayActivePaymentMethod />}
            />
            <Route
                exact
                path="payment-method/card"
                element={<KiwiPayActivePaymentMethodCard />}
            />
            <Route
                exact
                path="payment-method/success"
                element={<KiwiPayActivePaymentMethodSuccess />}
            />
            <Route
                exact
                path="payment-method/rejected"
                element={<KiwiPayActivePaymentMethodRejected />}
            />
            <Route
                exact
                path="payment-method/details"
                element={<KiwiPayActivePaymentMethodDetails />}
            />
            <Route
                exact
                path="move-payment-day"
                element={<KiwiPayActiveMovePaymentDay />}
            />
        </Route>
    </Route>
);

export default KiwiPayRoutes;
